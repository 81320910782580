import CheckIcon from "../images/icon/check.svg";
import Utm from "../components/_global/utm";

// images
import TopBackground from "../images/virtual-tradeshow.jpeg";
import Slide1 from "../images/slides/virtual-tradeshow/findnewgarmentsupplier-26032020-2880px.png";
import Slide2 from "../images/slides/virtual-tradeshow/notes-26032020-2880px.png";
import Slide3 from "../images/slides/virtual-tradeshow/inspiration-light-box-26032020-1920px.png";
import Slide4 from "../images/slides/virtual-tradeshow/inspiration-26032020-1920px.png";
import Slide5 from "../images/slides/virtual-tradeshow/rfq-26032020-2880px.png";
import Logos from "../images/partners-60.jpeg";

export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("virtual-tradeshow.topTitle"),
        colorClass: "colorLight",
      },
      subtitle: {
        text: t("virtual-tradeshow.topSubtitle"),
        colorClass: "colorLight",
      },
      cta: {
        text: t("homepage.ctaButton"),
        url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
        type: "mainColor",
      },
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackground,
        alt: t("virtual-tradeshow.imgAlt"),
        centerPosition: true,
      },
    },
    pageContent: [
      {
        info: {
          title: {
            text: t("virtual-tradeshow.scoutGarment"),
            colorClass: "colorDark",
          },
          icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
          separator: true,
          item: {
            text: [t("virtual-tradeshow.accessNetwork"), t("virtual-tradeshow.selectProfiles"), t("virtual-tradeshow.identifyBest")],
            colorClass: "colorDark",
          },
          bottomDiagonal: true,
          pushTop: true,
          topDiagonal: true,
          backgroundGrey: true,
          customPB: true,
          customPBText: true,
          images: [Slide1],
        },
        isTextFirst: true,
        cta: {
          text: t("virtual-tradeshow.registerFree"),
          url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
          type: "mainColor",
          extraClass: "bigWidth",
        },
      },
      {
        info: {
          title: {
            text: t("virtual-tradeshow.meetSuppliers"),
            colorClass: "colorDark",
          },
          icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
          separator: true,
          item: {
            text: [t("virtual-tradeshow.stayDevelopments"), t("virtual-tradeshow.strengthenRels"), t("virtual-tradeshow.stayOrganized")],
            colorClass: "colorDark",
          },
          bottomDiagonal: false,
          topDiagonal: false,
          backgroundGrey: false,
          customPB: false,
          customPBText: true,
          images: [Slide2],
        },
        isTextFirst: false,
        cta: {
          text: t("virtual-tradeshow.joinNow"),
          url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
          type: "mainColor",
          extraClass: "bigWidth",
        },
      },
      {
        info: {
          title: {
            text: t("virtual-tradeshow.discoverTrends"),
            colorClass: "colorDark",
          },
          icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
          separator: true,
          item: {
            text: [t("virtual-tradeshow.browseShowrooms"), t("virtual-tradeshow.exploreProductions"), t("virtual-tradeshow.directlyTouch")],
            colorClass: "colorDark",
          },
          bottomDiagonal: true,
          pushTop: false,
          topDiagonal: true,
          backgroundGrey: true,
          customPB: true,
          customPBText: true,
          images: [Slide3, Slide4],
        },
        isTextFirst: true,
      },
      {
        info: {
          title: {
            text: t("virtual-tradeshow.getQuotes"),
            colorClass: "colorDark",
          },
          icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
          separator: true,
          item: {
            text: [t("virtual-tradeshow.placeRequests"), t("virtual-tradeshow.targetQuotations"), t("virtual-tradeshow.manageProcess")],
            colorClass: "colorDark",
          },
          bottomDiagonal: false,
          topDiagonal: false,
          backgroundGrey: false,
          customPB: false,
          customPBText: true,
          images: [Slide5],
        },
        isTextFirst: false,
      },
    ],
    joinCommunity: [
      {
        text: t("virtual-tradeshow.joinCommunity"),
        colorClass: "colorDark",
        textType: "paragraph",
        extraClass: "font26 letterSpacing2",
      },
      {
        text: t("virtual-tradeshow.signUp"),
        url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
        type: "mainColor",
        textType: "button",
        extraClass: "bigWidth",
      },
    ],
    ourPartners: {
      title: {
        text: t("virtual-tradeshow.ourPartners"),
        colorClass: "colorDark",
        extraClass: "letterSpacing3 font34",
      },
      textCenter: true,
      separator: false,
      topDiagonal: false,
      backgroundGrey: false,
    },
    logosImg: {
      src: Logos,
      alt: t("virtual-tradeshow.logosAlt"),
    },
  };
};
