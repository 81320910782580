/**
 * Foursource page
 * https://foursource.com/virtual-tradeshow
 */

import * as React from "react";
import { withTrans } from "../i18n/withTrans";

import { pageTexts } from "../content/virtual-tradeshow";
import { associates } from "../content/pages/associatesPrincipal";

// components
import Layout from "../components/layout";
import TextImgBackgroundFull from "../components/textImgBackgroundFull";
import TitleSeparator from "../components/titleSeparator";
import Associates from "../components/_page/associates";
import SideBySide from "../components/sideBySide";
import Text from "../components/text";

class virtualTradeshowPage extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.text = pageTexts(this.t);
    this.associates = associates(this.t);
  }

  render() {
    return (
      <>
        <Layout pageName="virtual-tradeshow" whiteMenu={false}>
          <TextImgBackgroundFull info={this.text.topInfo}></TextImgBackgroundFull>
          {this.text.pageContent.map((content, i) => (
            <SideBySide key={i} info={content.info} isTextFirst={content.isTextFirst} cta={content.cta}></SideBySide>
          ))}
          <div className="pt-30 pb-50 text-center">
            <Text text={this.text.joinCommunity}></Text>
          </div>
          <div className="pt-75 topBottomDiagonal backgroundGrey">
            <Associates info={this.associates.cardsInfo}></Associates>
          </div>
          <div className="pt-30 text-center">
            <TitleSeparator info={this.text.ourPartners}></TitleSeparator>
            <img className="w-100 paddingSidesBig pt-50 pb-75" src={this.text.logosImg.src} alt={this.text.logosImg.alt} />
          </div>
        </Layout>
      </>
    );
  }
}

export default withTrans(virtualTradeshowPage);
